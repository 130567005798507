<template>
  <section class="contact-us" id="contact-us">
    <div class="tell-us">
      <div class="content">
        <div class="big-text">{{ $t("feedback_text") }}</div>
        <a
          class="button"
          target="_blank"
          href="https://docs.google.com/forms/d/e/1FAIpQLScIYCm090zcj37pAK2O-BWReedySKklcIWdPSnqolMxJZ1Exg/viewform?usp=sf_link"
          >{{ $t("feedback_button") }}</a
        >
      </div>
    </div>
    <div class="contact-teams">
      <div class="content">
        <div class="contact-title">{{ $t("contact_title") }}</div>
        <div class="contact-container">
          <div class="contact-block">
            <img class="contact-img" src="~@/assets/chocho-contact.png" />
            <div class="contact-name">{{ $t("contact_name_YCV") }}</div>
            <div class="contact-team">{{ $t("contact_role_YCV") }}</div>
            <div class="contact-text">{{ $t("contact_text_YCV") }}</div>
            <a class="button" href="mailto:info@youthvoicescommunity.org"
              >Contact YVC</a
            >
          </div>
          <div class="contact-block">
            <img class="contact-img" src="~@/assets/robert-contact.png" />
            <div class="contact-name">{{ $t("contact_name_YARID") }}</div>
            <div class="contact-team">{{ $t("contact_role_YARID") }}</div>
            <div class="contact-text">{{ $t("contact_text_YARID") }}</div>
            <a class="button" href="mailto:info@yarid.org">Contact YARID</a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ContactUs",
  props: ["content"],
};
</script>

<style lang="stylus" scoped>
#contact-us
  .tell-us
    background: #2F1F45
    text-align: center
    width 100%
    .content
      padding: 40px 0px
    .big-text
      color: #ECAE40
      r-font-size(45,63)
      font-weight: 700
    .button
      color #fff
      width 50%
      margin: 30px auto
      text-decoration: none
  .contact-teams
    background: #C3DCDD
    text-align: center
    padding-bottom: 30px

    .contact-title
      color: #2F1F45
      r-font-size(45,64)
      font-weight: 700
      margin-bottom: 50px
    .contact-container
      display: flex
      justify-content: space-evenly
      color: #2F1F45
      @media only screen and (max-width: 768px)
        align-items: center
        flex-direction: column
      .contact-block
        width 33%
        text-align: left
        r-font-size(20,30)
        @media only screen and (max-width: 768px)
          width: 60%
          text-align: center
          margin-bottom: 80px
        .contact-img
          width: 100%
          margin-bottom: 20px
          @media only screen and (max-width: 768px)
            width: 60%
        .contact-name, .contact-text
          font-weight: 600
        .contact-team
          font-weight: 400
          margin-bottom: 2em
        .button
          margin-top: 30px
          border-color: #2F1F45
          text-decoration: none
          color: #2F1F45
</style>
